import {RouteRecordRaw} from "vue-router";
import cms from "@/layouts/workspace/djhdb/cms";
import user from "@/layouts/workspace/djhdb/user";
import crm from "@/layouts/workspace/djhdb/crm";

export default <Array<RouteRecordRaw>>[
    {
        path: 'djhdb/activities',
        name: 'djhdb.activities',
        meta: {requireAuth: true},
        component: () => import(/* webpackChunkName: "djhdb_activities" */ './activities/DjhdbActivitiesPage.vue')
    },
    {
        path: 'djhdb/invite',
        name: 'djhdb.invite',
        meta: {requireAuth: true},
        component: () => import(/* webpackChunkName: "djhdb_invite" */ './invite/InvitePage.vue')
    },
    {
        path: 'djhdb',
        name: 'djhdb',
        meta: {requireAuth: true},
        children: [...cms, ...user,...crm]
    },
]

import * as mqtt from 'mqtt'
import {
    MqttClient, OnCloseCallback,
    OnConnectCallback,
    OnMessageCallback
} from 'mqtt'
import {IDisconnectPacket, IPublishPacket} from 'mqtt-packet'
import {IClientOptions, OnDisconnectCallback, OnErrorCallback} from 'mqtt/types/lib/client'
import {v4} from 'uuid'
import store from '@/lib/store'
import {StoreSessionActions} from "@/lib/store/modules/session/actions";

export declare type OnEventCallback = (packet: IPublishPacket) => void

class MQTT {
    c?: MqttClient
    private broker = 'wss://broker.mywsy.cn/mqtt'
    private subs = new Map<string, OnEventCallback>()
    private username = ''
    private password = ''

    constructor() {
        setTimeout(() => {
            if (store && store.state.session.account.wx_open_id.length > 0 && new Date(store.state.session.token.expire) > new Date()) {
                this.connect(store.state.session.account.wx_open_id, store.state.session.token.token)
            }
        }, 6000)
    }

    connect(user: string, pwd: string): void {
        if (this.c?.connected) return
        this.username = user
        this.password = pwd
        this.c = mqtt.connect(this.broker, {
            clientId: `console/${v4()}`,
            protocolVersion: 5,
            clean: true,
            username: user,
            reconnectPeriod: 0,
            password: pwd
        } as IClientOptions)
        this.c.on('connect', this.handleConnect)
        this.c.on('message', this.handleMessage)
        this.c.on('disconnect', this.handleDisconnect)
        this.c.on('error', this.handleError)
        this.c.on('close', this.handleClose)
        this.c.on('reconnect', this.handleReconnect)
    }

    private handleConnect: OnConnectCallback = () => {
        this.subs.forEach((i, k) => this.sub(k, i))
    }

    private handleMessage: OnMessageCallback = (topic: string, payload: Buffer, packet: IPublishPacket): void => {
        console.log('handleMessage', topic, payload.length, packet)
        this.subs.forEach((value: OnEventCallback, key: string) => {
            if (this.didPair(key, topic)) value(packet)
        })
    }

    private handleDisconnect: OnDisconnectCallback = (packet: IDisconnectPacket): void => {
        console.log(packet)
    }

    private handleError: OnErrorCallback = (error: Error): void => {
        if (error.message.includes('Not authorized')) {
            //
            console.log(error.message)
        }else {
            console.log(error)
        }

    }

    private handleClose: OnCloseCallback = (): void => {
        console.log('close')
    }

    private handleReconnect = (): void => {
        console.log('handleReconnect')
    }

    sub(topic: string, callback: OnEventCallback) {
        this.subs.set(topic, callback)
        console.log('subscribe', topic)
        if (this.c && this.c.connected) this.c.subscribe(topic, {qos: 1})
    }

    unsub(topic: string | string[]) {
        typeof topic === 'string' ? this.subs.delete(topic) : topic.forEach(t => this.subs.delete(t))
        console.log('unsubscribe', topic)
        if (this.c && this.c.connected) this.c.unsubscribe(topic)
    }

    private didPair(subTopic: string, topic: string): boolean {
        const subTopics = subTopic.split('/')
        const topics = topic.split('/')
        if (subTopics.length > topics.length) return false
        for (let i = 0; i < subTopics.length; i++) {
            if (subTopics[i] === '#') return true
            if (subTopics[i] !== topics[i] && subTopics[i] !== '+') return false
        }
        return true
    }
}

export default new MQTT()

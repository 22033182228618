import {createFromIconfontCN} from '@ant-design/icons-vue'
import request from "@/lib/request";
import {AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse, Method} from "axios";

export const IconFont = createFromIconfontCN({scriptUrl: require('../assets/font/iconfont.js')})

export interface IReqRequest<D = any> {
    url: string
    method?: Method | string
    params?: D
    data?: D
    header?: AxiosRequestHeaders
    success?: (res: any) => void
    fail?: (err: Error) => void
    complete?: () => void
}

export const req = (req: IReqRequest): void => {
    request.request<unknown, AxiosResponse, unknown>({
        url: req.url,
        method: req.method,
        data: req.data,
        params: req.params
    } as AxiosRequestConfig).then(rs => {
        if (rs.status == 200) req.success?.(rs.data)
        else req.fail?.(new Error(rs.data.err))
    }).catch(err => {
        req.fail?.(err)
    }).finally(() => {
        req.complete?.()
    })
}

export const reqAsync = (options: IReqRequest): Promise<any> => {
    return new Promise((resolve, reject) => {
        options.success = resolve
        options.fail = reject
        req(options)
    })
}
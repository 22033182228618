import {RouteRecordRaw} from "vue-router";

export default <Array<RouteRecordRaw>>[
    {
        path: 'user/users',
        name: 'user.users',
        meta: {requireAuth: true},
        component: () => import(/* webpackChunkName: "users_user" */ './users/DjhdbUsersPage.vue')
    },
    {
        path: 'user/picture',
        name: 'user.picture',
        meta: {requireAuth: true},
        component: () => import(/* webpackChunkName: "users_picture" */ './picture/PictureOrderPage.vue')
    },
    {
        path: 'user/theme',
        name: 'user.theme',
        meta: {requireAuth: true},
        component: () => import(/* webpackChunkName: "users_theme" */ './theme/ThemeOrderPage.vue')
    },
]
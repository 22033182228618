import {RouteRecordRaw} from "vue-router";

export default <Array<RouteRecordRaw>>[
    {
        path: 'business/mp/weixin/session',
        name: 'business.mp.weixin.session',
        meta: {requireAuth: true},
        component: () => import(/* webpackChunkName: "business_mp_weixin_session" */ './mp_weixin_session/MpWeixinSession.vue')
    },
]

<template>
  <router-view/>
</template>

<script setup lang="ts">
</script>

<style scoped lang="less">

</style>

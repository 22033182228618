import {RouteRecordRaw} from "vue-router";
import collect from "@/layouts/workspace/djhdb/crm/collect";

export default <Array<RouteRecordRaw>>[
    {
        path: 'crm',
        name: 'crm',
        meta: {requireAuth: true},
        children: [ ...collect,]
    },
    {
        path: 'crm/leads',
        name: 'crm.leads',
        meta: {requireAuth: true},
        component: () => import(/* webpackChunkName: "crm_leads" */ './leads/LeadsPage.vue')
    },
]
<template>
  <a-result status="500" title="不支持此浏览器" sub-title="请安装chrome浏览器.">
    <template #extra>
<!--      <a-button type="primary" @click="toHome">-->
<!--        Back Home-->
<!--      </a-button>-->
    </template>
  </a-result>
</template>

<script setup lang="ts">
</script>

<style scoped lang="less">

</style>

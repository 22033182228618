import {RouteRecordRaw} from "vue-router";

export default <Array<RouteRecordRaw>>[
    {
        path: 'collect/sjd',
        name: 'collect.sjd',
        meta: {requireAuth: true},
        component: () => import(/* webpackChunkName: "collect_sjd" */ './sjd/ShangJiaDao.vue')
    },
    {
        path: 'collect/sjd_analysis',
        name: 'collect.sjd_analysis',
        meta: {requireAuth: true},
        component: () => import(/* webpackChunkName: "collect_sjd_analysis" */ './sjd_analysis/SjdAnalysisPage.vue')
    },
]
